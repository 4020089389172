import axios from "axios";

interface GetListParams {
  project_id: number;
  user_id: number
  daily: boolean;
}

export class UserDailyLearningReportRepository {
  async get(params: GetListParams): Promise<any> {
    return axios.get(
      process.env.VUE_APP_DOMAIN + `v1/projects/${params.project_id}/users/${params.user_id}/learning_report`,
      {params: params}
    ).then((response: any) => {
      return response.data;
    });
  }
}
