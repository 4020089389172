import { onMounted, Ref, ref } from "vue";
import { UserDailyLearningReportRepository } from '@/repositories/UserDailyLearningReportRepository';
// import { UserRepository } from '@/repositories/UserRepository';
import { ProjectUserRepository } from '@/repositories/ProjectUserRepository';

export const useUserLearningReportLogic = (project_id: Ref<any>) => {
  // const userRepository = new UserRepository();
  const projectUserRepository = new ProjectUserRepository(project_id.value);
  const reportRepository = new UserDailyLearningReportRepository();
  ProjectUserRepository
  const userList = ref<{ id: number, name: string }[]>([]);
  const loading = ref(false);
  const loaded = ref(false);
  const data = ref({});

  onMounted(async () => {
    userList.value = (await projectUserRepository.getList())
      .map((user: { id: number, name: string }) => {
        return { id: user.id, name: user.name };
      });
  });

  const load = async (params: { project_id: any, user_id: any, daily: any }) => {
    loading.value = true;

    try {
      data.value = await reportRepository.get({
        project_id: params.project_id,
        user_id: params.user_id,
        daily: params.daily
      });
    } catch (e) {
      //
    }

    loading.value = false;
    loaded.value = true;
  };

  return {
    userList,
    load,
    loading,
    loaded,
    data,
  };
};
