import { computed, ref } from "vue";

export const useTrainingDetailsTable = () => {
  const rawData = ref([]);
  const noChallengeOnly = ref(false);

  const setRawData = (values: any) => {
    rawData.value = values;
  };

  const data = computed(() => {
    if (noChallengeOnly.value) {
      return rawData.value.filter((row: {do_count: number}) => {
        return row.do_count === 0;
      });
    }

    return rawData.value;
  });

  return {
    data,
    setRawData,
    noChallengeOnly,
  };
};
