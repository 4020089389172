import axios from "axios";
import { ProjectUser } from '@/types/project_user'

export class ProjectUserRepository {
  private url: string;

  constructor(project_id: number) {
    this.url = ProjectUserRepository.url(project_id);
  }

  async getList(): Promise<ProjectUser[]> {
    return axios.get(this.url).then((response) => {
      return response.data.users;
    });
  }

  async add(userIds: number[]): Promise<void> {
    return axios.post(this.url, { user_ids: userIds });
  }
  async changeOwnerFlg(user_id: number, owner_flg: boolean): Promise<void> {
    return axios.post(this.url + user_id + "/change_owner_flg", { owner_flg: owner_flg });
  }

  async delete(user: ProjectUser): Promise<void> {
    return axios.delete(this.url + user.id)
  }

  private static url(project_id: number) {
    return process.env.VUE_APP_DOMAIN + 'v1/projects/' + project_id + '/project_users/'
  }
}
