import {
  computed,
  ref,
} from 'vue'
import {
  ChartOptions,
  ChartData,
} from "chart.js";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export const useCorrectRateGraph = () => {
  const option: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        ticks: {
          callback: (value: any) => {
            return  value + '%';
          },
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.parsed.x}%`;
          },
        }
      },
      legend: {
        align: 'end',
      },
    }
  };

  const labels = ref([]);
  const data = ref([]);

  const chartData = computed<ChartData<'bar'>>(() => ({
    labels: labels.value,
    datasets: [
      {
        label: "正解率",
        data: [...data.value, 100],
        borderColor: "rgb(234, 82, 66, 0.7))", // TODO
        backgroundColor: "rgb(234, 82, 66, 0.7)", // TODO
      },
    ],
  }));

  const setData = (values: any) => {
    data.value = values.map((d: {rate: number}) => {
      return d.rate;
    });
    labels.value = values.map((d: {category: string}) => {
      return d.category;
    });
  };

  return {
    option,
    chartData,
    setData,
  };
};
