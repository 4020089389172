import {
  computed,
  ref,
} from 'vue'
import {
  ChartOptions,
  ChartData,
} from "chart.js";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export const useLastMonthGraph = () => {
  const option: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        align: 'end',
      },
    }
  };

  const labels = ref<string[]>([]);
  const chartBarData = ref<number[]>([]);
  const chartLineData = ref<number[]>([]);

  const tableAllData = ref<number[]>([]);
  const tableData = ref<number[]>([]);
  const tableDataTotalSize = ref(0);
  const tablePageSize = 8;

  const chartData = computed<ChartData>(() => {
    return({
      labels: labels.value,
      datasets: [
        {
          type: 'bar',
          label: '当日',
          data: [...chartBarData.value],
          borderColor: 'rgb(234, 82, 66, 0.7)',
          backgroundColor: 'rgb(234, 82, 66, 0.7)',
          order: 2,
        },
        {
          type: 'line',
          label: '積み上げ',
          data: [...chartLineData.value],
          borderColor: 'rgb(44, 154, 122, 0.7)',
          backgroundColor: 'rgb(44, 154, 122, 0.7)',
          order: 1,
        },
      ],
    })
  }
  );

  const setData = (values: any) => {
    labels.value = [...Array(30)].map((_, index) => {
      const d = new Date();
      d.setDate(d.getDate() - index)
      return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()}`;
    }).reverse();

    const v: any = {};
    labels.value.forEach((value: string) => {
      v[value] = 0;
    });

    if (!values.data) {
      chartBarData.value = [];
      chartLineData.value = [];
      tableDataTotalSize.value = 0;
      tableData.value = [];
      return;
    }

    values.data.map((d: any) => {
      return v[d.date]++;
    });
    chartBarData.value = Object.values(v);

    let temp = values.stack_offset;
    chartLineData.value = [...chartBarData.value.map((v) => temp += v)];

    tableAllData.value = values.data;
    tableDataTotalSize.value = tableAllData.value.length
    tableData.value = values.data.slice(0, tablePageSize);
  };

  const changePage = (page: number) => {
    const offset = (page - 1) * tablePageSize;
    tableData.value = tableAllData.value.slice(offset, offset + tablePageSize);
  };

  return {
    option,
    chartData,
    tableData,
    setData,
    tableDataTotalSize,
    tablePageSize,
    changePage,
  };
};
